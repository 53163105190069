import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
// import ReactDOM from 'react-dom'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'

const DEFAULT_IMAGES = [
    { id: '4', source: full04, thumbnail: thumb04, caption: '24-hour Website', description: 'This is a website I built for a client in 24-hours. Built with HTML5, CSS3, flexbox/ grids, and minimal Javascript. This is not the live site; but, rather my dev version with a 1-page design.'},  
    { id: '3', source: full03, thumbnail: thumb03, caption: 'One-page Website', description: 'Built with HTML5, CSS3, Javascript and JQuery, this website is SIMPLE and FAST, and OPTIMIZED for the elderly user - using images and large fonts as the primary method of communicating ideas.'},
    { id: '1', source: full01, thumbnail: thumb01, caption: 'Web App', description: 'Built with HTML5, CSS3 and JQuery, this really shows what you can do with just Flexbox and Grids. Add a webmanifest and a backend, its a craft beer app.'},
    { id: '2', source: full02, thumbnail: thumb02, caption: 'Graphic Design', description: 'This was a fun exploration of 3D-Lettering mixed with another passion of mine, rock climbing. I found an image of Plumbers Crack, NV, and imposed the letters C-L-I-M-B into the rock.'},
    { id: '5', source: full05, thumbnail: thumb05, caption: 'Hi-Def Prototype', description: 'My ASU Senior Capstone Project: a "Website Prototype Design and Development" for the livingwithtourette.com website.'},
    { id: '6', source: full06, thumbnail: thumb06, caption: 'Print Media', description: 'A whimsical imagination of our world in the future. Are you ready for it? I created this cover from "scratch". I found an image, removed the unecessary, then added: the dog, female android and her chair, shadows and the pixel-perfect text.'}];
class HomeIndex extends React.Component {

    render() {
        const siteTitle = "art'Teryxx_WDD | CREATIVE THINKING :: FOCUSED ON RESULTS"
        const siteDescription = "Site description"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>UX | Web Design | Web Development</h2>
                            <h3>Creative Thinking :: Focused on Results</h3>
                        </header>

                        <div className="services-container">
                            <div className="box green">
                                <p className="workingTitle">web/app development</p>
                                <p className="servicesDescription">I'm perpetually expanding my skill sets. I'm comfortable working in HTML/CSS, VanillaJS and iOS/Swift, and React is coming soon! I prefer to hand code all my work.</p>
                            </div>
                            <div className="box yellow">
                                <p className="workingTitle">web design</p>
                                <p className="servicesDescription">I'm a results-focused Designer/Developer. I love writing HTML and CSS and prefer to hand code my work, but if a CMS, such as, Wordpress or Contentful, or a particular framework works better for you, than that's a better solution.</p>
                            </div>
                            <div className="box red">
                                <p className="workingTitle">ux design & testing</p>
                                <p className="servicesDescription">I love sketching, animating, wireframing, prototyping, and testing. Full gammut UX Design and Testing services provided. AdobeXD / SketchApp / InVision / Balsamiq, etc. depending on your needs and/or philosophical bent.<br></br> 
                                I always start projects on paper/glassboard... I find it is easier and quicker to brainstorm and iterate on, particularly when conceptualizing something completely new. All FINAL designs are professionially rendered and delivered in digital format, of course.</p>
                            </div>
                        </div>
                    </section>

                    <section id="two">
                        <h2>Recent Work</h2>
                        <Gallery images={DEFAULT_IMAGES.map(({ id, source, thumbnail, caption, description }) => ({
                            source,
                            thumbnail,
                            caption,
                            description
                        }))} />

                        <ul className="actions">
                            <li><a href="https://patrickjcook.com" className="button2">Full Portfolio</a></li>
                        </ul>
                    </section>

                    <section id="three">
                        <h2>Get In Touch</h2>
                        <p>The best way to reach me is via <a href="https://twitter.com/azparrotthead">Twitter</a> or <a href="https://www.linkedin.com/in/patrickjcook">LinkedIn</a>, but I am available through traditional channels. 
                        Why not schedule an online meeting so we can discuss how I can best help you achieve your goals!</p>
                        <div className="row">
                            <div className="8u 12u$(small)">
                                <ul className="actions">
                                    <li><a href="https://calendly.com/artteryxx/15min" className="button2">Schedule a Meeting</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>
                        
            </Layout>
        )
    }
}
 
export default HomeIndex